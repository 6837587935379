<template>
  <div>
    <v-row class="ma-0 pa-4 align-center" id="create_vendor_label">
      Creater Vendor
    </v-row>

    <div class="mt-1">
      <v-form ref="form" v-model="formValid" lazy-validation @submit.prevent="createVendor">
        <v-card class="mx-4 pa-5 cardBoxShadow rounded-lg">
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="6" lg="3">
              <span class="fsize14 font-weight-bold primaryColor" id="vendor_details_label">Vendor Details</span>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="5" id="vendor_name_col">
              <div class="pb-1 secondaryColor fsize16" id="vendor_name_label">Vendor Name</div>
              <v-text-field autofocus dense height="48px" id="vendor_name_inp" v-model="vendorName" placeholder="Enter the Vendor Name" :rules="vendorNameRules" autocomplete="off" outlined>
              </v-text-field>
              <div class="pb-1 secondaryColor fsize16" id="contact_person_val">Contact Person Name</div>
              <v-text-field dense height="48px" v-model="personName" id="contact_person_inp" placeholder="Enter the Contact Person Name" :rules="personNameRules" autocomplete="off" outlined>
              </v-text-field>
              <div class="pb-1 secondaryColor fsize16" id="app_log_label">Logo (24*24px)</div>
                  <!-- :rules="logoRules"  -->
                  <v-file-input type="file" height="48px" id="app_log_inp" dense v-model="logo" placeholder="Please upload a square image (24*24px is preferred)" append-icon="mdi-folder-open" prepend-icon="" autocomplete="off" outlined>
                  </v-file-input>
              <v-row no-gutters>
                <v-col class="pr-2" id="email_col">
                  <div class="pb-1 secondaryColor fsize16" id="email_label">Email ID</div>
                  <v-text-field dense height="48px" v-model="emailId" id="email_inp" placeholder="Enter the Email ID" :rules="emailIdRules" autocomplete="off" outlined>
                  </v-text-field>
                </v-col>
                <v-col class="pl-2">
                  <div class="pb-1 secondaryColor fsize16" id="mobile_number_col">Mobile Number</div>
                  <v-text-field type="tel" dense height="48px" id="mobile_number_inp" v-model="mobileNo" placeholder="Enter the Mobile Number" :rules="mobileNoRules" autocomplete="off" outlined>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="pr-2" id="redirection_url_col">
                  <div class="pb-1 secondaryColor fsize16 d-flex align-center" id="redirection_url_label">Redirect URL <v-icon id="redirection_url_icon" class="ml-2" small>mdi-information-outline</v-icon></div>
                  <v-text-field dense height="48px" v-model="redirectUrl" id="redirection_url_inp" placeholder="Enter the Redirect URL" :rules="urlRules" autocomplete="off" outlined>
                  </v-text-field>
                </v-col>
                <v-col class="pl-2" id="postback_url_col">
                  <div class="pb-1 secondaryColor fsize16 d-flex align-center" id="postback_url_label">Postback URL <v-icon id="postback_url_icon" class="ml-2" small>mdi-information-outline</v-icon></div>
                  <v-text-field dense height="48px" v-model="postbackUrl" id="postback_url_inp" placeholder="Enter the Postback URL" :rules="postbackUrlRules" autocomplete="off" outlined>
                  </v-text-field>
                </v-col>
              </v-row>
              <div class="pb-1 secondaryColor fsize16" id="dexcription_label">Description</div>
              <v-textarea outlined v-model="description" name="input-7-4" id="dexcription_inp" placeholder="Enter the Description" :rules="descriptionRules"></v-textarea>
              <div class="d-flex align-center mt-5 mb-2">
            <v-checkbox dense v-model="checkbox" class="ma-0" hide-details id="check_box" ></v-checkbox> <span class="fsize14" id="terms_pdf">I accept the <a href="termsPdf.pdf" target="_blank">Terms and Conditions of this policy</a></span>
          </div>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4"></v-col>
          </v-row>
          <div class="pa-4 d-flex justify-end">
          <v-btn height="40" width="96" depressed outlined @click="$router.back()" class="text-capitalize" id="cancel_btn" min-width="80">Cancel</v-btn>
          <v-btn type="submit" height="40" width="96" color="primary" depressed class="ml-4 text-capitalize" id="save_btn" min-width="80" :disabled="!checkbox">
            Save
          </v-btn>
        </div>
        </v-card>

        
      </v-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  data: () => ({
    vendorName: "",
    personName: "",
    mobileNo: "",
    emailId: "",
    logo: null,
    redirectUrl: "",
    postbackUrl: "",
    description: "",
    formValid: true,
    vendorNameRules: [(v) => !!v || "Vendor Name can not be empty"],
    personNameRules: [(v) => !!v || "Contact Person Name can not be empty"],
    mobileNoRules: [
      (v) => !!v || "Mobile Number can not be empty",
      (v) => /^[0-9]{10}$/.test(v) || "Mobile Number must be 10 digits",
    ],
    emailIdRules: [
      (v) => !!v || "Email ID can not be empty",
      (v) =>
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Email ID must be valid",
    ],
    logoRules: [(v) => !!v || "Logo can not be empty"],
    urlRules: [(v) => !!v || "Redirect URL can not be empty"],
    descriptionRules: [(v) => !!v || "Description can not be empty"],
    postbackUrlRules: [(v) => !!v || "Postback URL can not be empty"],
    checkbox:false,
  }),
  computed:{
    ...mapState('vendor', ['vendorLoader'])
  },
  methods: {
    createVendor() {
      if (this.$refs.form.validate() && !this.vendorLoader) {
        if (this.$route.query.type == "edit") {
          this.$store.dispatch("vendor/updateVendor", {
            appName: this.vendorName,
            redirectUrl: this.redirectUrl,
            postbackUrl: this.redirectUrl,
            description: this.description,
            appId: this.$route.query.appId,
            contactName: this.personName,
            mobieNo: this.mobileNo,
            email: this.emailId,
          });
        } else {
          this.$store.dispatch("vendor/createVendor", {
            appName: this.vendorName,
            redirectUrl: this.redirectUrl,
            postbackUrl: this.redirectUrl,
            description: this.description,
            contactName: this.personName,
            mobieNo: this.mobileNo,
            email: this.emailId,
          });
        }
      }
    },
  },
  created() {
    if (this.$route.query.type == "edit") {
      var data = JSON.parse(localStorage.getItem("currentAppData"));
      this.vendorName = data.appName;
      this.personName = data.contactName;
      this.mobileNo = data.mobieNo;
      this.emailId = data.email;
      this.redirectUrl = data.redirectUrl;
      this.description = data.description;
    }
  },
};
</script>